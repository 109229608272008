import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout/Layout"
import {
  IoAlarmOutline,
  IoCalendarOutline,
  IoPersonAddOutline,
} from "react-icons/io5"
import { AiOutlineEuroCircle } from "react-icons/ai"
import { TbBuildingMonument } from "react-icons/tb"
import { GrDocumentText } from "react-icons/gr"
import HandSeed from "../assets/hand_seed.svg"

// https://hire.workwise.io/hr-praxis/personalsuche/stellenanzeige-schreiben#aufbau
const stelle = () => {
  return (
    <Layout>
      <section className="sm:px-12 py-24 mx-auto max-w-7xl min-h-full bg-[url('../images/stelle/vertical-shot-river-background-fir-trees-mountains.jpg')] bg-cover">
        <div className="container rounded-3xl shadow-md bg-slate-50/70">
          <div className="flex flex-wrap">
            <div className="w-full px-4">
              <div className="sm:mb-12 lg:mb-20 max-w-[680px]">

                {/*  */}
                {/* Überschrift */}
                <h3 class="mt-4 text-4xl font-bold leading-none tracking-tighter text-primary md:text-5xl lg:text-7xl">
                  Willkommen im Team
                </h3>
                <h4 class="mb-2 text-2xl font-bold leading-none tracking-tighter text-secondary md:text-3xl lg:text-5xl">
                  Physiotherapeut / Masseur (m/w/d)
                </h4>

                {/*  */}
                {/* Bilder */}
                {/* https://tailwindcss.com/docs/align-content */}
                <div class="grid grid-cols-2 gap-4 content-around xs:h-36 md:h-72 mb-4">
                  <div>
                    <StaticImage
                      className="mx-auto rounded-3xl shadow-2xl"
                      src="../images/stelle/professional-female-physiotherapist-giving-shoulder-massage-blonde-woman.jpg"
                      placeholder="tracedSVG"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                  <div>
                    <StaticImage
                      className="mx-auto rounded-3xl shadow-2xl"
                      src="../images/stelle/physio.jpg"
                      placeholder="tracedSVG"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                </div>


                {/*  */}
                {/* Was wir bieten */}
                <div class="grid grid-cols-2 md:grid-cols-4 gap-4 content-around xs:my-4">
                  <div class="group bg-secondary hover:bg-primary active:bg-red-500 focus:bg-blue-500 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl">
                    <div>
                      <span class="inline-flex items-center justify-center p-2 bg-primary group-hover:bg-secondary rounded-md shadow-lg">
                        <IoCalendarOutline class="content-center" size={25} />
                      </span>
                    </div>
                    <h3 class="text-slate-900 mt-5 text-base font-medium tracking-tight">
                      Ab sofort
                    </h3>
                    {/* <p class="text-slate-500 mt-2 text-sm">
                      The Zero Gravity Pen can be used to write in any
                      orientation, including upside-down. It even works in outer
                      space.
                    </p> */}
                  </div>

                  <div class="group bg-secondary hover:bg-primary rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl">
                    <div>
                      <span class="inline-flex items-center justify-center p-2 bg-primary group-hover:bg-secondary rounded-md shadow-lg">
                        <AiOutlineEuroCircle class="content-center" size={25} />
                      </span>
                    </div>
                    <h3 class="text-slate-900 mt-5 text-base font-medium tracking-tight">
                      40.000 € pro Jahr (verhandelbar)
                    </h3>
                  </div>

                  <div class="group bg-secondary hover:bg-primary rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl">
                    <div>
                      <span class="inline-flex items-center justify-center p-2 bg-primary group-hover:bg-secondary rounded-md shadow-lg">
                        <IoAlarmOutline class="content-center" size={25} />
                      </span>
                    </div>
                    <h3 class="text-slate-900 mt-5 text-base font-medium tracking-tight">
                      Voll- oder Teilzeit
                    </h3>
                   
                  </div>

                  <div class="group bg-secondary hover:bg-primary rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl">
                    <div>
                      <span class="inline-flex items-center justify-center p-2 bg-primary group-hover:bg-secondary rounded-md shadow-lg">
                        <GrDocumentText class="content-center" size={25} />
                      </span>
                    </div>
                    <h3 class="text-slate-900 mt-5 text-base font-medium tracking-tight">
                      Festanstellung
                    </h3>
                  </div>
                </div>

                {/* Was solltest du mitbringen */}
                <section class="shadow-md p-6 rounded-md bg-slate-50/50">
                  <h5 class="text-2xl font-bold leading-none tracking-tighter mb-4">
                    <IoPersonAddOutline class="inline-block" size={25} />
                    <span> </span>
                    Was Du mitbringen solltest:
                  </h5>
                  Du hast eine abgeschlossene Ausbildung als
                  <ul className="text-md leading-relaxed sm:leading-relaxed text-body-color list-outside list-disc my-4 ml-4">
                    <li>Physiotherapeut (m/w/d) und/oder </li>
                    <li>Masseur und medizinischer Bademeister (m/w/d)</li>
                  </ul>
                  und verfügst darüber hinaus über folgende Eigenschaften:
                  <ul className="text-md leading-relaxed sm:leading-relaxed text-body-color list-outside list-disc my-4 ml-4">
                    <li>Du bist freundlich, flexibel, belastbar, kommunikationsstark und zuverlässig</li>
                    <li>Hast Freude am Umgang mit Patienten</li>
                    <li>Probierst und lernst gerne neue Sachen</li>
                    <li>Weiterbildungen in folgenden Bereichen wären wünschenswert: manuelle Therapie, manuelle Lymphdrainage</li>
                    <li>Strukturierte Arbeitsweise</li>
                  </ul>
                </section>

                {/* Über uns */}
                <section class="shadow-md p-6 rounded-md bg-slate-50/50 mt-6">
                <h5 class="text-2xl font-bold leading-none tracking-tighter">
                  <TbBuildingMonument class="inline-block" size={25} />
                  <span> </span>
                  Über uns:
                </h5>
                <p>
                Seit nunmehr über 35 Jahren arbeiten wir zusammen mit unseren Patienten in der Lymphologischen Schwerpunktpraxis. Die Praxis ist sehr zentral und gut erreichbar in Waltenhofen bei Kempten im schönen Allgäu gelegen. 

                Seit Kurzem in der 2. Generation, voller Motivation und Tatendrang, suchen wir ab sofort Verstärkung für unser Team.

                Es ist unser Anspruch sowohl dem Patienten als auch dem Therapeuten in ihren Bedürfnissen für die Therapie und auch darüber hinaus gerecht zu werden.
                </p>
                </section>

                {/* Was bieten wir */}
                <section class="shadow-md p-6 rounded-md bg-slate-50/50 mt-6">
                <h5 class="text-2xl font-bold leading-none tracking-tighter">
                  <HandSeed class="inline-block w-10" />
                  <span> </span>
                  Was wir Dir bieten:
                </h5>
                <p>
                  In unserem Familienunternehmen sind wir mit unseren Ansätzen
                  und Methoden durch stete Fortbildung und der Zusammenarbeit
                  mit führenden Lehrinstituten teilweise Vor- und Wegbereiter in
                  der Praxis und bieten:
                  <ul className="text-md leading-relaxed sm:leading-relaxed text-body-color list-outside list-disc my-4 md:columns-2 justify-start ml-4">
                    <li>
                      Mitsprache bei Deiner Arbeitszeitgestaltung, wir
                      garantieren Dir eine gute Work-Life-Balance
                    </li>
                    <li>30/60-Minuten-Takt</li>
                    <li>Unterstützung zu Fortbildungen</li>
                    <li>Wöchentliche Patienten Besprechungen</li>
                    <li>Lernen mit den Profis: 2 Kollegen als Fachlehrer für Lymphdrainage</li>
                    <li>Interne Fortbildungen</li>
                    <li>
                      Spannende und abwechslungsreiche Tätigkeiten; bei uns wird es Dir
                      nicht langweilig
                    </li>
                    <li>Eine faire und ansprechende Vergütung</li>
                    <li>Fundierte fachliche Einarbeitung</li>
                    <li>Unabhängiges, selbstständiges und eigenverantwortliches Arbeiten</li>
                    <li>Familienfreundlich</li>
                    <li>Wir leben eine offene Feedback Kultur</li>
                    <li>So wenig Bürokratie wie möglich</li>
                    <li>Jobrad</li>
                  </ul>
                </p>
                </section>

                <p class="mt-2 font-semibold">
                  Bei uns bist Du quasi Dein eigener Chef.<br/>
                  Bist Du bereit für den nächsten Schritt?</p>
                <p class="mt-2 font-semibold">
                  Dann bewirb Dich jetzt:<br/>
                  entweder schriftlich oder per E-Mail an:
                </p>
                <ul className="text-md leading-relaxed sm:leading-relaxed text-body-color list-none my-2">
                  <li>Friederike Bromberger</li>
                  <li>Rathausstraße 9</li>
                  <li>87448 Waltenhofen</li>
                  <li>Telefon: 08303-858</li>
                  <li>E-Mail: info @ zml-allgäu.de</li>
                </ul>

                {/* <p className="text-md font-semibold leading-relaxed sm:leading-relaxed text-body-color mt-4 mb-1">
                  Dein Aufgabengebiet
                </p>
                <ul className="text-md leading-relaxed sm:leading-relaxed text-body-color list-inside list-disc">
                  <li>
                    Behandlung unserer Patienten (m/w/d) hauptsächlich mit
                    manueller Lymphdrainage{" "}
                  </li>
                  <li>
                    Darüber hinaus führst du Massagen und Bewegungstherapien
                    durch{" "}
                  </li>
                  <li>Außerdem muss die Rezeption teilweise</li>
                </ul>
                <p className="text-md font-semibold leading-relaxed sm:leading-relaxed text-body-color mt-4 mb-1">
                  Ihr Profil
                </p>
                Sie haben eine abgeschlossene Ausbildung
                <ul className="text-md leading-relaxed sm:leading-relaxed text-body-color list-inside list-disc">
                  <li>
                    als Masseur und medizinischer Bademeister (m/w/d) bzw.
                    Physiotherapeut (m/w/d)?
                  </li>
                  <li>
                    Sie verfügen über erste Berufserfahrungen und Arbeiten gerne
                    selbständig?
                  </li>
                  <li> und besitzen rudimentäre Computerkenntnisse?</li>
                </ul> */}
              </div> 
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default stelle
